

require("@rails/ujs").start()
import Prism from"../lib/prism.js";
import Vue from "vue/dist/vue.esm";
import AssetLayoutEditor from "../components/asset_layout_editor/editor.vue";
import AssetTypeField from "../components/asset_type_field.vue";
import CompanyGrid from "../components/grids/CompanyGrid.vue";
import ActivityLogGrid from "../components/grids/ActivityLogGrid.vue";
import AssetTypeIcon from "../components/asset_type_icon.vue";
import AssetTypeSorter from "../components/asset_type_sorter.vue";
import AssetPasswordCopier from "../components/asset_password_copier.vue";
import Search from "../components/search.vue";
import Toast from "../components/toast.vue";
import Updater from "../components/updater.vue";
import Tester from "../components/tester.vue";
import QuickNoter from "../components/quick_noter.vue";
import PasswordEditor from "../components/password_editor.vue";
import DashboardPicturePicker from "../components/DashboardPicturePicker.vue";
import QuickAdder from "../components/quick_adder.vue";
import PasswordViewer from "../components/password_viewer.vue";
import Onboarder from "../components/Onboarder.vue";
import CheckOutbounder from "../components/CheckOutbounder.vue";
import PasswordLocationChanger from "../components/PasswordLocationChanger.vue";
import DashboardDater from "../components/DashboardDater.vue";
import AgreementsViewer from "../components/AgreementsViewer.vue";
import DarkModer from "../components/dark_moder.vue";
import EditableName from "../components/EditableName.vue";
import NewArticleButton from "../components/NewArticleButton.vue";
import Mapbox from "../components/Mapbox.vue";
import Legacier from "../components/legacier.vue";
import NameList from "../components/NameList.vue";
import OtpCopier from "../components/OtpCopier.vue";
import Copier from "../components/copier.vue"
import ArticleWriter from "../components/ArticleWriter.vue"
import ArticleExpirer from "../components/ArticleExpirer.vue"
import Restricter from "../components/restricter.vue"
import ResizableSidebar from "../components/ResizableSidebar.vue"
import LayoutSorter from "../components/layout_sorter.vue"
import CustomFastFacter from "../components/CustomFastFacter.vue"
import VaultPasswordCopier from "../components/VaultPasswordCopier.vue"
import Alerter from "../components/alerter.vue"
import Vault from "../components/vault.vue"
import Pinner from "../components/Pinner.vue"
import SecureNoter from "../components/SecureNoter"
import SecureNoteRevealer from "../components/SecureNoteRevealer"

import EmailAutofocuser from "../components/EmailAutofocuser";
import AssetsSidebar from "../components/assets_sidebar.vue"
import BulkAssetActioner from "../components/BulkAssetActioner.vue"
import BulkPasswordActioner from "../components/BulkPasswordActioner.vue"
import DomainInfo from "../components/domain_info";
import FolderEditor from "../components/folder_editor";
import CardTag from "../components/CardTag";
import FolderChooser from "../components/folder_chooser";
import DatePicker from "../components/date_picker";
import CompanyAdder from "../components/CompanyAdder";
import Roler from "../components/roler";
import Progression from "../components/progression";
import Rematcher from "../components/rematcher";
import SyncBar from "../components/sync_bar";
import Sharer from "../components/sharer";
import Relativer from "../components/relativer";
import Contenter from "../components/contenter";
import Searcher from "../components/searcher";
import PasswordColorPicker from "../components/PasswordColorPicker";
import PasswordTagger from "../components/PasswordTagger";
import Writer from "../components/writer";
import EditFolderButton from "../components/EditFolderButton.vue";
import Permissioner from "../components/permissioner";
import Embedder from "../components/embedder";
import PortalExpander from "../components/portal_expander";
import Dropper from "../components/dropper";
import ProcessChart from "../components/process_chart";
import QuickRematcher from "../components/quick_rematcher";
import DashboardItemSorter from "../components/DashboardItemSorter";
import QualityChart from "../components/quality_chart";
import Duplicator from "../components/duplicator";
import ProcedureQuickViewer from "../components/procedure_quick_viewer";
import Importer from "../components/importer/importer";
import Linker from "../components/linker";
import Tagger from "../components/tagger";
import StateRegionPicker from "../components/state_region_picker";
import BasicInfoExpander from "../components/basic_info_expander";
import Mover from "../components/mover"
import ManageModal from "../components/manage_modal"
import Relater from "../components/relater";
import Processor from "../components/processor";
import Icon from "../components/icon";
import LiongardTabber from "../components/LiongardTabber";
import CompanyGrabber from "../components/company_grabber";
import FileManager from "../components/file_manager"
import PhotoManager from "../components/photo_manager"
import AppHeader from "../components/AppHeader"
import Ringer from "../components/ringer"
import Index from "../components/index";
import Commenter from "../components/commenter";
import AssetLayoutSwitcher from "../components/AssetLayoutSwitcher";
import ExporterCheckAll from "../components/ExporterCheckAll";
import Dnser from "../components/dnser";
import OtpInputer from "../components/otp_inputer";
import OtpGenerator from "../components/otp_generator";
import Timer from "../components/timer";
import PortalPasswordViewer from "../components/portal_password_viewer";
import PortalQuickAdder from "../components/portal-quick-adder";
import axios from "axios";
import VueFlashMessage from 'vue-flash-message';
import vueDebounce from 'vue-debounce'
import VueLoadImage from 'vue-load-image'
import Loader from '../components/loader.vue'
import vueCountryRegionSelect from 'vue-country-region-select'
import store from "../store"

document.addEventListener('DOMContentLoaded', () => {

  window.Prism = window.Prism || {};
  window.Prism.manual = true;

  Vue.use(VueFlashMessage);
  Vue.use(vueCountryRegionSelect)
  Vue.use(vueDebounce, {
    listenTo: ['oninput', 'onkeyup']
  })
  
  Vue.use({
      install (Vue) {
      Vue.prototype.$api = axios.create({
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      })
    }
  })
  
  const app = new Vue({
    el: '#app',
    store,
    data: function () {
      return {
        forceClose: false
      }
    },
    mounted () {
       Prism.highlightAll()
    },
    components: {
      'vue-load-image': VueLoadImage,
      'loader': Loader,
      Index,
      AssetTypeField,
      OtpCopier,
      PhotoManager,
      ProcedureQuickViewer,
      AssetTypeIcon,
      AssetTypeSorter,
      NewArticleButton,
      EditFolderButton,
      AppHeader,
      LiongardTabber,
      EditableName,
      Relativer,
      Pinner,
      ActivityLogGrid,
      Embedder,
      DashboardPicturePicker,
      ExporterCheckAll,
      Timer,
      Sharer,
      AssetsSidebar,
      StateRegionPicker,
      PasswordLocationChanger,
      AgreementsViewer,
      FileManager,
      QuickRematcher,
      SecureNoter,
      SecureNoteRevealer,
      QuickNoter,
      Alerter,
      EmailAutofocuser,
      AssetLayoutSwitcher,
      AssetPasswordCopier,
      Dnser,
      Mover,
      Onboarder,
      QuickAdder,
      OtpGenerator,
      BulkAssetActioner,
      BulkPasswordActioner,
      VaultPasswordCopier,
      CustomFastFacter,
      OtpInputer,
      PasswordTagger,
      ManageModal,
      ProcessChart,
      QualityChart,
      PortalExpander,
      CompanyGrabber,
      CompanyAdder,
      ArticleExpirer,
      Duplicator,
      Vault,
      LayoutSorter,
      Ringer,
      DashboardItemSorter,
      Dropper,
      Relater,
      Searcher,
      Legacier,
      BasicInfoExpander,
      PortalPasswordViewer,
      PortalQuickAdder,
      ResizableSidebar,
      Roler,
      Importer,
      FolderEditor,
      FolderChooser,
      Tester,
      Tagger,
      DarkModer,
      DashboardDater,
      Mapbox,
      NameList,
      CardTag,
      Updater,
      Writer,
      Permissioner,
      Progression,
      CompanyGrid,
      Commenter,
      Contenter,
      Processor,
      DatePicker,
      Icon,
      SyncBar,
      CheckOutbounder,
      PasswordColorPicker,
      ArticleWriter,
      Rematcher,
      Linker,
      Toast,
      DomainInfo,
      AssetLayoutEditor,
      Search,
      PasswordViewer,
      PasswordEditor,
      Copier,
      Restricter
    }
  })
})